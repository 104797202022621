/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama - iqbalwahyudip.com
==============================================================================================*/
@import "sass-lib";

body {
  color: #333;
  font-size: 15px;
  font-family: $opensans;
  position: relative;
  overflow-x: hidden;
}
figure {
  margin: 0;
  padding: 0;
  img {
    vertical-align: middle;
  }
}
img {
  border: 0;
  max-width: 100%;
  height: auto;
}
a {
  color: $blue;
  &:hover {
    color: $blue;
  }
}
p {
  margin: 1px 0 15px;
  line-height: 20px;
}
input,
textarea,
select {
  font-size: 12px;
  border: none;
  border: 1px solid #d8d8d8;
  padding: 10px;
  margin: 0;
  color: #666;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: $opensans;
}
input[type="checkbox"],
input[type="radio"] {
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
h1 {
  font-size: 60px;
  font-weight: 700;
  font-family: $raleway;
}
h2 {
  font-size: 44px;
  font-weight: 700;
  font-family: $raleway;
}
h3 {
  font-size: 28px;
  &.braleway {
    font-family: $raleway;
    font-size: 30px;
    font-weight: 700;
  }
}
h4 {
  font-size: 22px;
  &.tlight {
    font-size: 24px;
    line-height: 1.3;
    font-family: $raleway;
    font-weight: 300;
  }
  &.braleway {
    font-family: $raleway;
    font-weight: 700;
  }
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
/* structure 
----------------------------------------------------------------------------------------------*/
section {
  position: relative;
}
.wrapper {
  width: 1400px;
  margin: 0 auto;
  @include afterboth;
}

.tblue {
  color: $blue;
  font-weight: 700;
}
.link-black {
  color: #111;
  font-size: 15px;
  font-weight: 700;
}

.label {
  display: inline-block;
  padding: 10px 12px;
  @include borderRadius(5px);
  color: $white;
  background: rgba(31, 31, 31, 0.8);
  font-size: 14px;
  font-weight: 700;
  font-family: $opensans;

  &.lbl-green {
    background: rgba(27, 174, 159, 0.6);
  }
  &.lbl-blue {
    background: #05abdd;
    color: $white;
  }
}

.icwp {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: top center;
  &:hover {
    background-position: bottom center;
  }

  &.ic_tag {
    background-image: url("../images/material/ic_tags.png");
  }
}

/* header
----------------------------------------------------------------------------------------------*/
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding-top: 45px;
  @include boxSizing(border-box);
  @include transition(0.2s all ease-out);
  .box-logo {
    float: left;
    .logo {
      float: left;
      margin-right: 72px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -40px;
        width: 1px;
        background: #373a3b;
      }
    }
    .tx-logo {
      float: right;
      color: $blue;
      font-size: 28px;
      font-weight: 700;
      font-family: $montserrat;
    }
  }
  .nav-right {
    float: right;
    @include transition(0.2s all ease-out);
    .box-search {
      float: left;
      margin-left: 42px;
      &.newslist {
        display: none;
      }
    }
    .box-lang {
      float: left;
    }
  }

  &.bgdark {
    background: #151b1f;
    height: 130px;
  }

  &.float {
    background: #151b1f;
    height: 80px;
    padding: 20px 0 0;
    .nav-right {
      padding-top: 7px;
    }
  }
}
.box-search {
  position: relative;
  > span {
    display: block;
    width: 25px;
    height: 25px;
    background: url("../images/material/ic_search.png") no-repeat center;
    cursor: pointer;
  }
  .drop-search {
    position: absolute;
    top: -7px;
    right: -7px;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    input {
      &[type="text"] {
        background: $black;
        color: $white;
        padding: 0 35px 0 15px;
        height: 40px;
        width: 200px;
        font-size: 14px;
        border: none;
        @include boxSizing(border-box);
        @include andplaceholder {
          color: #bfbfbf;
          font-style: italic;
        }
      }
      &[type="submit"] {
        position: absolute;
        top: 7px;
        right: 7px;
        width: 25px;
        height: 25px;
        padding: 0;
        border: none;
        background: url("../images/material/ic_search.png") no-repeat center;
      }
    }
  }
}
.box-lang {
  font-weight: 700;
  position: relative;
  > span {
    display: block;
    color: #bfbfbf;
    position: relative;
    cursor: pointer;
    padding: 5px 30px 5px 10px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      width: 10px;
      background: url("../images/material/arr-bottom.png") no-repeat center;
    }
  }
  .drop-lang {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    padding: 15px 10px;
    display: none;
    a {
      color: #bfbfbf;
      display: block;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        color: $blue;
      }
    }
  }
}

/* middle
----------------------------------------------------------------------------------------------*/
.paddheadertop {
  padding-top: 130px;
}
.paddheadermoretop {
  padding-top: 210px;
}
.paddsec {
  padding: 45px 0 100px;
}
.paddsecbot {
  padding-bottom: 100px;
}
.box-slider-home {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 220px 0 190px;
  position: relative;
  overflow: hidden;
  .left {
    position: absolute;
    top: 320px;
    left: 0;
    right: 0;
    width: 1400px;
    margin: 0 auto;
    color: $white;
    height: 0;
    z-index: 2;
    h1 {
      margin-bottom: 30px;
    }
    a {
      font-size: 19px;
      font-weight: 700;
      text-decoration: underline;
    }
    .in-text-slidehome {
      width: 496px;
      z-index: 2;
      position: relative;
    }
  }
  .sliderhome {
    margin-left: -300px;
  }
  .arrow-slidehome {
    position: absolute;
    bottom: 112px;
    left: 0;
    right: 0;
    width: 1400px;
    margin: 0 auto;
    .arrow-slide {
      float: right;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        background: #303131;
        width: 1px;
      }
      a {
        display: block;
        float: left;
        width: 24px;
        height: 24px;
        background-position: bottom center;
        background-repeat: no-repeat;
        cursor: pointer;
        &.prev {
          background-image: url("../images/material/prev-slide.png");
          margin-right: 30px;
        }
        &.next {
          background-image: url("../images/material/next-slide.png");
          margin-left: 30px;
        }

        &:hover {
          background-position: top center;
        }
      }
    }
  }
}
.sliderhome {
  position: relative;
  .list {
    margin: 0 15px;
    position: relative;
    display: block;
    @include boxShadow(0 0 30px 5px rgba(0, 0, 0, 0.2));
    > figure {
      position: relative;
      overflow: hidden;
      @include borderRadius(5px);
      /*&:before{content: "";position: absolute;bottom: 0;left: 0;right: 0;top: 0;background: url('../images/material/fg-banner.png') no-repeat center bottom;background-size: 100%;z-index: 1;}*/
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
        /*background: url('../images/material/fg-banner.png') no-repeat center bottom;background-size: 100%;*/
        background: rgba(0, 0, 0, 0);
        background: -moz-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 20%,
          rgba(11, 11, 11, 1) 100%
        );
        background: -webkit-gradient(
          left top,
          left bottom,
          color-stop(0%, rgba(255, 255, 255, 0)),
          color-stop(20%, rgba(50, 50, 50, 0)),
          color-stop(100%, rgba(11, 11, 11, 1))
        );
        background: -webkit-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 20%,
          rgba(11, 11, 11, 1) 100%
        );
        background: -o-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 20%,
          rgba(11, 11, 11, 1) 100%
        );
        background: -ms-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 20%,
          rgba(11, 11, 11, 1) 100%
        );
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 20%,
          rgba(11, 11, 11, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#292929', GradientType=0 );
      }
      img {
        width: 100%;
        @include transition(0.2s transform ease-out);
      }
    }
    .btn-play {
      position: absolute;
      top: 0;
      left: 15px;
      right: 15px;
      bottom: 80px;
      margin: auto;
      width: 103px;
      height: 103px;
      background: url(../images/material/ic_play.png) no-repeat center;
      z-index: 4;
    }
    .desc {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 38px 33px;
      color: $white;
      z-index: 2;
      h4 {
        font-weight: 300;
        line-height: 32px;
        height: 64px;
        overflow: hidden;
      }
      .label {
        margin-bottom: 15px;
      }
    }

    &:hover {
      figure {
        img {
          @include transform(scale(1.05));
        }
      }
    }
  }
}

.content-home {
  background: #f3f3f3 url("../images/material/bg-homepage.png") no-repeat bottom
    center;
  background-size: 100%;
  padding: 100px 0 115px;
}
.box-news {
  background: $white;
  @include borderRadius(5px);
  margin-bottom: 40px;
  @include boxShadow($shadow);
  overflow: hidden;
  position: relative;
  @include afterboth;
  > .left {
    width: 560px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    h2 {
      margin-bottom: 10px;
    }
    a {
      text-decoration: underline;
      font-family: $raleway;
      font-size: 19px;
      font-weight: 700;
    }
    figure {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: url("../images/material/fg-news-md.png") no-repeat bottom
          center;
        background-size: 100%;
      }
      img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: cover;
      }
    }
    .text {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 70px 70px;
      color: $white;
    }
  }
  > .right {
    width: 783px;
    padding: 50px 53px 0 0;
    @include boxSizing(border-box);
  }
}
.in-box-news {
  @include afterboth;
  > .left {
    width: 400px;
  }
  > .right {
    width: 240px;
  }
  .potrait-th {
    padding-bottom: 30px;
    border-bottom: 1px solid #f2f2f2;
  }
}

.box-th {
  position: relative;
  margin-bottom: 28px;
  display: block;
  figure {
    position: relative;
    @include borderRadius(5px);
    overflow: hidden;
    /*&:before{content:"";position: absolute;top: 0;left: 0;bottom: 0;right: 0;background: rgba(0,0,0,0.05) url('../images/material/fg-news-md.png') no-repeat bottom center;background-size: 100%;z-index: 1;}*/
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      /*background: rgba(0,0,0,0.05) url('../images/material/fg-news-md.png') no-repeat bottom center;background-size: 100%;*/
      background: rgba(0, 0, 0, 0);
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(50, 50, 50, 0) 20%,
        rgba(11, 11, 11, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(20%, rgba(50, 50, 50, 0)),
        color-stop(100%, rgba(11, 11, 11, 1))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(50, 50, 50, 0) 20%,
        rgba(11, 11, 11, 1) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(50, 50, 50, 0) 20%,
        rgba(11, 11, 11, 1) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(50, 50, 50, 0) 20%,
        rgba(11, 11, 11, 1) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(50, 50, 50, 0) 20%,
        rgba(11, 11, 11, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#292929', GradientType=0 );
    }
    img {
      width: 100%;
      @include transition(0.2s all ease-out);
    }
  }
  .btn-play {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 80px;
    margin: auto;
    width: 80px;
    height: 80px;
    background: url(../images/material/ic_play.png) no-repeat center;
    background-size: 100%;
    z-index: 4;
  }
  .text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 30px 20px;
    color: $white;
    z-index: 2;
    h5 {
      font-size: 17px;
      line-height: 24px;
      height: 48px;
      overflow: hidden;
    }
    .label {
      margin-bottom: 15px;
    }
  }

  &:hover {
    figure {
      img {
        @include transform(scale(1.05));
      }
    }
  }
}

.side-th {
  position: relative;
  margin-bottom: 28px;
  display: block;
  color: $black;
  @include afterboth;
  figure {
    position: relative;
    float: left;
    width: 40%;
    @include borderRadius(5px);
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.05) url("../images/material/fg-news-sm.png")
        no-repeat bottom center;
      background-size: 100%;
      z-index: 2;
    }
    img {
      width: 100%;
      @include transition(0.2s all ease-out);
    }
  }
  .text {
    float: right;
    width: 55%;
    padding: 14px 0 0;
    p {
      font-size: 15px;
      line-height: 24px;
      height: 48px;
      overflow: hidden;
      margin-bottom: 0;
    }
    .tblue {
      display: block;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  &.side-video {
    figure {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        bottom: 0;
        margin: auto;
        width: 40px;
        height: 40px;
        background: url(../images/material/ic_play.png) no-repeat center;
        background-size: 100%;
        z-index: 4;
      }
    }
  }

  &:hover {
    color: $black;
    figure {
      img {
        @include transform(scale(1.05));
      }
    }
  }
}

.potrait-th {
  position: relative;
  margin-bottom: 28px;
  display: block;
  color: $black;
  @include afterboth;
  figure {
    position: relative;
    margin-bottom: 18px;
    @include borderRadius(5px);
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.05)
        url("../images/material/fg-news-mid-sm.png") no-repeat bottom center;
      background-size: 100%;
      z-index: 2;
    }
    img {
      width: 100%;
      @include transition(0.2s all ease-out);
    }
  }
  .text {
    p {
      font-size: 14px;
      line-height: 20px;
      height: 40px;
      overflow: hidden;
      margin-bottom: 0;
    }
    .tblue {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
    }
  }

  &.side-video {
    figure {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        bottom: 0;
        margin: auto;
        width: 60px;
        height: 60px;
        background: url(../images/material/ic_play.png) no-repeat center;
        background-size: 100%;
        z-index: 4;
      }
    }
  }
  &.tx-only {
    border-bottom: none;
    padding-bottom: 0;
    figure {
      display: none;
    }
  }

  &:hover {
    color: $black;
    figure {
      img {
        @include transform(scale(1.05));
      }
    }
  }
}

.tx-list {
  margin-bottom: 25px;
  display: block;
  color: $black;
  p {
    font-size: 14px;
    line-height: 20px;
    max-height: 40px;
    overflow: hidden;
    margin-bottom: 0;
    height: auto;
  }
  .tblue {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
  }
  &:hover {
    color: $black;
  }
}

.box-tag {
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
  margin-bottom: 40px;
  padding: 55px 65px 55px 78px;
  @include borderRadius(5px);
  @include boxShadow($shadow);
  @include afterboth;
  h3 {
    font-weight: 700;
    line-height: 35px;
  }
}
.tags {
  a {
    display: inline-block;
    margin: 0 10px;
    height: 40px;
    line-height: 40px;
    background: $white;
    color: $black;
    padding: 0 36px;
    @include borderRadius(40px);
    font-size: 15px;
    @include transition(0.2s all ease-out);
    @include boxShadow(0 0 20px -5px rgba(0, 0, 0, 0.1));
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background: #d8d8d8;
    }
    &.active {
      background: $blue;
      color: $black;
      font-weight: 700;
    }
  }

  &.withmarg {
    a {
      margin: 0 5px 18px;
    }
  }
}

.box-popular-news {
  background: $white;
  @include boxShadow($shadow);
  overflow: hidden;
  @include borderRadius(5px);
  padding-right: 30px;
  @include afterboth;
  > .left {
    width: 355px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    figure {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: cover;
      }
    }
    h3 {
      font-weight: 700;
      font-family: $raleway;
    }
    .text {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 20px 52px;
      text-align: center;
      color: $white;
    }
  }
  > .right {
    width: 980px;
    padding-top: 36px;
  }
}

.slidepopular {
  position: relative;
  .list {
    position: relative;
    margin: 0 10px;
    color: $black;
    display: block;
    figure {
      position: relative;
      overflow: hidden;
      @include borderRadius(5px);
      /*&:before{content:"";position: absolute;top: 0;left: 0;right: 0;bottom: 0;background: url('../images/material/fg-news-popular.png') no-repeat bottom center;background-size: 100%;z-index: 2;}*/
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        /*background: url('../images/material/fg-news-popular.png') no-repeat bottom center;background-size: 100%;*/
        background: rgba(0, 0, 0, 0);
        background: -moz-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 20%,
          rgba(11, 11, 11, 1) 100%
        );
        background: -webkit-gradient(
          left top,
          left bottom,
          color-stop(0%, rgba(255, 255, 255, 0)),
          color-stop(20%, rgba(50, 50, 50, 0)),
          color-stop(100%, rgba(11, 11, 11, 1))
        );
        background: -webkit-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 20%,
          rgba(11, 11, 11, 1) 100%
        );
        background: -o-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 20%,
          rgba(11, 11, 11, 1) 100%
        );
        background: -ms-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 20%,
          rgba(11, 11, 11, 1) 100%
        );
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 20%,
          rgba(11, 11, 11, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#292929', GradientType=0 );
      }
      img {
        width: 100%;
        @include transition(0.2s all ease-out);
      }
    }
    .btn-play {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 60px;
      margin: auto;
      width: 80px;
      height: 80px;
      background: url("../images/material/ic_play.png") no-repeat center;
      background-size: 100%;
      z-index: 4;
    }
    .desc {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 30px 28px;
      color: $white;
      z-index: 3;
      p {
        line-height: 30px;
        font-size: 18px;
        margin-bottom: 0;
        height: 60px;
        overflow: hidden;
      }
      .label {
        margin-bottom: 10px;
      }
    }

    &:hover {
      color: $black;
      figure {
        img {
          @include transform(scale(1.05));
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    z-index: 2;
    font-size: 0;
    color: transparent;
    padding: 0;
    border: none;
    width: 50px;
    height: 50px;
    background: $white;
    @include borderRadius(100%);
    cursor: pointer;
    &:before {
      content: "";
      width: 11px;
      height: 18px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    &.slick-prev {
      left: -15px;
      &:before {
        background: url("../images/material/prev-slide-grey.png") no-repeat
          bottom center;
      }
    }
    &.slick-next {
      right: -15px;
      &:before {
        background: url("../images/material/next-slide-grey.png") no-repeat
          bottom center;
      }
    }

    &:hover {
      &:before {
        background-position: top center;
      }
    }
  }
}

.highlight {
  background: url("../images/material/bg-highlight.jpg") no-repeat center;
  background-size: cover;
  padding: 115px 0 86px;
  color: $white;
  overflow: hidden;
  .slidervideo {
    margin-left: -1420px;
  }
  .sliderwp {
    position: relative;
    left: 240px;
  }
}

.top-highlight {
  margin-bottom: 35px;
  @include afterboth;
  .left {
    h3 {
      display: inline-block;
      margin-right: 30px;
      font-weight: 700;
      font-family: $raleway;
    }
    a {
      font-weight: 700;
      font-family: $raleway;
      text-decoration: underline;
      font-size: 19px;
    }
  }
  .arrow-slide {
    position: relative;
    @include afterboth;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      background: #303131;
      width: 1px;
    }
    a {
      display: block;
      float: left;
      width: 24px;
      height: 24px;
      background-position: bottom center;
      background-repeat: no-repeat;
      cursor: pointer;
      &.prev {
        background-image: url("../images/material/prev-slide.png");
        margin-right: 23px;
      }
      &.next {
        background-image: url("../images/material/next-slide.png");
        margin-left: 23px;
      }

      &:hover {
        background-position: top center;
      }
    }
  }
}

.slidervideo {
  .list {
    position: relative;
    margin: 0 15px;
    display: block;
    figure {
      position: relative;
      @include borderRadius(5px);
      overflow: hidden;
      @include boxShadow($shadow);
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: url("../images/material/fg-thvideo.png") no-repeat center
          bottom;
        background-size: 100%;
        z-index: 2;
      }
      img {
        width: 100%;
        @include transition(0.2s all ease-out);
      }
    }
    .btn-play {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 80px;
      margin: auto;
      width: 103px;
      height: 103px;
      background: url("../images/material/ic_play.png") no-repeat center;
      z-index: 4;
    }
    .desc {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 40px 33px;
      color: $white;
      z-index: 3;
      h4 {
        line-height: 34px;
        max-height: 68px;
        overflow: hidden;
        font-weight: 300;
      }
      .label {
        margin-bottom: 15px;
      }
    }

    &:hover {
      figure {
        img {
          @include transform(scale(1.05));
        }
      }
    }
  }
}

.sliderwp {
  position: relative;
  height: 432px;
  overflow: hidden;
  .inner-sliderwp {
    .list {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 15px;
      display: block;
      margin-top: 30px;
      figure {
        position: relative;
        @include borderRadius(5px);
        overflow: hidden;
        @include boxShadow($shadow);
        width: 560px;
        /*&:before{content:"";position: absolute;top: 0;left: 0;bottom: 0;right: 0;background: url('../images/material/fg-thvideo.png') no-repeat center bottom;background-size: 100%;z-index: 2;}*/
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 2;
          /*background: url('../images/material/fg-thvideo.png') no-repeat center bottom;background-size: 100%;*/
          background: rgba(0, 0, 0, 0);
          background: -moz-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(50, 50, 50, 0) 20%,
            rgba(11, 11, 11, 1) 100%
          );
          background: -webkit-gradient(
            left top,
            left bottom,
            color-stop(0%, rgba(255, 255, 255, 0)),
            color-stop(20%, rgba(50, 50, 50, 0)),
            color-stop(100%, rgba(11, 11, 11, 1))
          );
          background: -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(50, 50, 50, 0) 20%,
            rgba(11, 11, 11, 1) 100%
          );
          background: -o-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(50, 50, 50, 0) 20%,
            rgba(11, 11, 11, 1) 100%
          );
          background: -ms-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(50, 50, 50, 0) 20%,
            rgba(11, 11, 11, 1) 100%
          );
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(50, 50, 50, 0) 20%,
            rgba(11, 11, 11, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#292929', GradientType=0 );
        }
        img {
          width: 100%;
          @include transition(0.2s all ease-out);
        }
      }
      .btn-play {
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        bottom: 80px;
        margin: auto;
        width: 103px;
        height: 103px;
        background: url("../images/material/ic_play.png") no-repeat center;
        z-index: 4;
      }
      .desc {
        position: absolute;
        bottom: 0;
        left: 15px;
        right: 15px;
        padding: 0 40px 33px;
        color: $white;
        z-index: 3;
        h4 {
          line-height: 34px;
          max-height: 68px;
          overflow: hidden;
          font-weight: 300;
        }
        .label {
          margin-bottom: 15px;
        }
      }

      &:hover {
        figure {
          img {
            @include transform(scale(1.05));
          }
        }
      }
    }
  }
}

.box-sosmed {
  a {
    display: inline-block;
    margin-left: 15px;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    @include transition(0.2s all ease-out);
    &:first-child {
      margin-left: 0;
    }
    &.fb {
      background-image: url("../images/material/ic_fb.png");
    }
    &.tw {
      background-image: url("../images/material/ic_tw.png");
    }
    &.ig {
      background-image: url("../images/material/ic_ig.png");
    }
    &.in {
      background-image: url("../images/material/ic_linkedin.png");
    }

    &:hover {
      @include transform(scale(0.9));
    }
  }
}

.box-subnav {
  position: relative;
  @include boxShadow($shadow);
  z-index: 100;
  @include afterboth;

  .box-tags,
  .select-custom,
  .box-search-nav {
    float: left;
  }
  .linev {
    float: left;
    margin: 0 40px;
    width: 1px;
    height: 22px;
    background: #dedede;
  }
  .nav-left {
    padding: 28px 0;
    float: left;
  }
  .nav-right {
    padding: 28px 0;
    float: right;
  }
}

.subnav {
  a {
    display: inline-block;
    padding: 0 28px;
    border-right: 1px solid #dedede;
    color: #888888;
    font-size: 18px;
    @include transition(0.2s all ease-out);
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: none;
    }
    &:hover {
      color: #333333;
    }
    &.active {
      color: #333333;
      font-weight: 700;
    }
  }
}

.bg-grey {
  background-color: #f9f9f9;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
}

.box-tags {
  .btn-tags {
    color: #54677b;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    background: url("../images/material/arr-select.png") no-repeat center right;
    padding-right: 30px;
    cursor: pointer;
    .icwp {
      vertical-align: middle;
      margin-left: 5px;
    }
  }
  .drop-tags {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: none;
    @include afterboth;
    .in-drop-tags {
      background: #ebebeb;
      width: 1380px;
      padding: 30px 40px 12px;
      @include boxSizing(border-box);
      margin: 0 auto;
      max-height: 790px;
      overflow-y: auto;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      /* width */
      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #ebebeb;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
    .tags-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.9);
      z-index: -1;
      cursor: pointer;
      .close-tags {
        position: absolute;
        top: 0;
        right: 0;
        width: 70px;
        height: 70px;
        background: #ebebeb;
        @include transition(0.2s all ease-out);
        cursor: pointer;
        span {
          width: 20px;
          height: 3px;
          background: #333;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -1px 0 0 -10px;
          @include transform(rotate(45deg));
          &:last-child {
            @include transform(rotate(-45deg));
          }
        }

        &:hover {
          background: #bdbdbd;
        }
      }
    }
  }
  .tags {
    a {
      margin: 0 5px 18px;
    }
  }
}

.select-custom {
  position: relative;
  .btn-select {
    display: block;
    color: #54677b;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    background: url("../images/material/arr-select.png") no-repeat center right;
    padding: 0 30px 0 10px;
    cursor: pointer;
  }
  .drop-select {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    background: $white;
    padding: 10px;
    max-height: 200px;
    overflow: auto;
    display: none;
    a {
      color: #888888;
      font-weight: 400;
      line-height: 24px;
      display: block;
      &:hover {
        color: #333;
      }
      &.active {
        font-weight: 700;
        color: #333;
      }
    }
  }
}

.box-search-nav {
  position: relative;
  margin-left: 80px;
  &:before {
    content: "";
    position: absolute;
    top: -28px;
    left: -40px;
    bottom: -28px;
    width: 1px;
    background: #dedede;
  }
  input[type="text"] {
    border: none;
    padding: 0;
    font-size: 16px;
    color: #333;
    width: 340px;
    padding-right: 30px;
    @include boxSizing(border-box);
    @include andplaceholder {
      color: #999;
    }
  }
  .btn-search-nav {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    background: url("../images/material/ic_search_blue.png") no-repeat center;
    border: none;
  }
}

.contentwp {
  h3 {
    font-family: $raleway;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
  }
  h4 {
    font-size: 24px;
    line-height: 40px;
    font-family: $raleway;
    font-weight: 300;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 31px;
    line-height: 48px;
    float: left;
    width: 50%;
    margin: 0 20px 15px 0;
    color: #00b9f1;
    font-family: $raleway;
    font-weight: 500;
  }
  p {
    font-size: 16px;
    line-height: 32px;
  }

  video,
  iframe {
    width: 100%;
    display: block;
  }
  iframe {
    height: 400px;
  }
  blockquote {
    clear: both;
    position: relative;
    background: url("../images/content/bg-quotes.jpg") no-repeat center;
    background-size: cover;
    color: $white;
    padding: 100px 90px 90px;
    margin: 0;
    font-family: $raleway;
    font-weight: 300;
    font-size: 24px;
    line-height: 40px;
    &:before {
      content: "";
      position: absolute;
      top: 40px;
      left: 90px;
      width: 34px;
      height: 30px;
      background: url("../images/material/ic_quotes.png") no-repeat center;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 50px;
      right: 90px;
      width: 34px;
      height: 30px;
      background: url("../images/material/ic_quotes-back.png") no-repeat center;
    }
  }

  ul,
  ol {
    li {
      font-size: 16px;
      line-height: 32px;
      padding-left: 30px;
      position: relative;
    }
  }
  ul {
    counter-reset: my-badass-counter;
    li {
      &:before {
        content: counter(my-badass-counter);
        counter-increment: my-badass-counter;
        position: absolute;
        top: 0;
        left: 5px;
      }
    }
  }
  ol {
    li {
      &:before {
        content: "";
        position: absolute;
        top: 12px;
        left: 5px;
        width: 8px;
        height: 8px;
        background: #333;
        @include borderRadius(100%);
      }
    }
  }
  > img {
    width: 100%;
  }
  table {
    width: 100% !important;
    th,
    td {
      font-size: 14px;
      line-height: 24px;
      background: #f7f7f7;
      border-bottom: 1px solid #ebebeb;
      padding: 10px 15px;
      text-align: left;
      word-break: break-word;
    }
    thead {
      tr {
        th,
        td {
          background: #202629;
          color: #fff;
          font-weight: 700;
        }
      }
    }
  }
}

.box-thumb {
  margin: 0 -15px;
  @include afterboth;
  .ls-thumb {
    width: 33.3333333333333333%;
    float: left;
    padding: 0 15px;
    @include boxSizing(border-box);
    margin-bottom: 42px;
    display: block;
    p {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0;
      max-height: 56px;
      overflow: hidden;
    }
    figure {
      position: relative;
      overflow: hidden;
      @include borderRadius(5px 5px 0 0);
      /*&:before{content: "";position: absolute;top: 0;left: 0;bottom: 0;right: 0;background: url('../images/material/fg-thumb.png')no-repeat bottom center;background-size: 100%;z-index: 2;}*/
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        /*background: url('../images/material/fg-thumb.png')no-repeat bottom center;background-size: 100%;*/
        background: rgba(0, 0, 0, 0);
        background: -moz-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 30%,
          rgba(11, 11, 11, 1) 100%
        );
        background: -webkit-gradient(
          left top,
          left bottom,
          color-stop(0%, rgba(255, 255, 255, 0)),
          color-stop(30%, rgba(50, 50, 50, 0)),
          color-stop(100%, rgba(11, 11, 11, 1))
        );
        background: -webkit-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 30%,
          rgba(11, 11, 11, 1) 100%
        );
        background: -o-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 30%,
          rgba(11, 11, 11, 1) 100%
        );
        background: -ms-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 30%,
          rgba(11, 11, 11, 1) 100%
        );
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(50, 50, 50, 0) 30%,
          rgba(11, 11, 11, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#292929', GradientType=0 );
      }
      img {
        @include borderRadius(5px 5px 0 0);
        width: 100%;
        @include transition(0.2s transform ease-out);
      }
      .label {
        position: absolute;
        bottom: 25px;
        left: 30px;
        z-index: 3;
      }
    }
    .desc {
      padding: 30px 30px 35px;
      background: $white;
      @include borderRadius(0 0 5px 5px);
    }
    .in-thumb {
      @include borderRadius(5px);
      @include boxShadow($shadow);
      display: block;
      color: #333;
    }

    &:nth-child(3n + 1) {
      clear: both;
    }

    &.ls-video {
      figure {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 15px;
          right: 15px;
          bottom: 0;
          margin: auto;
          width: 100px;
          height: 100px;
          background: url(../images/material/ic_play.png) no-repeat center;
          background-size: 100%;
          z-index: 4;
        }
      }
    }

    &:hover {
      figure {
        img {
          @include transform(scale(1.05));
        }
      }
    }
  }
}

.pagination {
  text-align: center;
  @include afterboth;
  a {
    display: inline-block;
    color: #555555;
    font-weight: 300;
    width: 24px;
    height: 24px;
    line-height: 24px;
    @include borderRadius(5px);
    margin: 0 5px;
    text-align: center;
    &:hover {
      text-decoration: underline;
    }
    &.active {
      color: $white;
      font-weight: 700;
      background: $blue;
    }
    &.prev,
    &.next {
      width: auto;
      font-family: $raleway;
      font-weight: 700;
      font-size: 14px;
    }
    &.prev {
      float: left;
    }
    &.next {
      float: right;
    }
  }
  .dots {
    display: inline-block;
    margin: 0 5px;
    font-weight: 300;
    color: #555;
    font-size: 16px;
    line-height: 24px;
  }
}

.box-sideshare {
  position: relative;
  .share-side {
    position: absolute;
    top: 0;
    margin-left: -100px;
  }
}

.share-side {
  label,
  a {
    display: block;
    text-align: center;
  }
  label {
    font-size: 15px;
    color: #7d7d7d;
    margin-bottom: 20px;
  }
  a {
    margin-bottom: 20px;
  }
}

.sidearticle {
  @include afterboth;
  article {
    width: 64.28%;
    float: left;
  }
  aside {
    width: 31.42%;
    float: right;
  }
}

.slider-lg {
  .list {
    position: relative;
    /*&:before{content: "";position: absolute;top: 0;left: 0;bottom: 0;right: 0;z-index: 1;background: url('../images/material/fg-slider-lg.png') no-repeat center bottom;background-size: 100%;}*/
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      /*background: url('../images/material/fg-slider-lg.png') no-repeat center bottom;background-size: 100%;*/
      background: rgba(255, 255, 255, 0);
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(21, 21, 21, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(50%, rgba(255, 255, 255, 0)),
        color-stop(100%, rgba(21, 21, 21, 1))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(21, 21, 21, 1) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(21, 21, 21, 1) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(21, 21, 21, 1) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(21, 21, 21, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#292929', GradientType=0 );
    }
    img {
      width: 100%;
    }
    .title-slider {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      font-family: $raleway;
      font-weight: 300;
      color: $white;
      font-size: 24px;
      line-height: 1.3;
      padding: 0 40px 40px;
      @media (min-width: 1490px) {
        font-size: 28px;
      }
      @media (max-width: 1320px) {
        font-size: 22px;
      }
      @media (max-width: 1240px) {
        font-size: 20px;
      }
      @media (max-width: 1200px) {
        font-size: 30px;
      }
      @media (max-width: 1030px) {
        font-size: 28px;
      }
      @media (max-width: 978px) {
        font-size: 24px;
      }
      @media (max-width: 870px) {
        font-size: 22px;
      }
      @media (max-width: 810px) {
        font-size: 20px;
      }
      @media (max-width: 640px) {
        font-size: 18px;
      }
      @media (max-width: 580px) {
        font-size: 16px;
      }
      @media (max-width: 530px) {
        font-size: 14px;
      }
      @media (max-width: 470px) {
        font-size: 12px;
      }
      @media (max-width: 420px) {
        font-size: 10px;
      }
    }
  }
}
.slider-thumb {
  padding: 27px 45px;
  background: #ededed;
  position: relative;
  .list {
    position: relative;
    margin-right: 15px;
    cursor: pointer;
    img {
      width: 100%;
      @include borderRadius(5px);
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 3px solid $blue;
      @include borderRadius(5px);
      opacity: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &.slick-current {
      &:before {
        opacity: 1;
      }
    }
  }
  .slick-arrow {
    z-index: 1;
    position: absolute;
    top: 50%;
    font-size: 0px;
    color: transparent;
    border: none;
    padding: 0;
    width: 11px;
    height: 18px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: bottom center;
    cursor: pointer;
    margin-top: -5px;
    &.slick-prev {
      left: 18px;
      background-image: url("../images/material/prev-slide-grey.png");
    }
    &.slick-next {
      right: 18px;
      background-image: url("../images/material/next-slide-grey.png");
    }
    &:hover {
      background-position: top center;
    }
  }
}
.bg-grey-tag {
  background: #ebebeb;
  padding: 20px 30px 5px;
  @include afterboth;
  label {
    float: left;
    line-height: 40px;
    margin-right: 20px;
  }
  .tags {
    float: left;
  }
}

.other-news {
  background: #151b1f;
  color: $white;
  @include borderRadius(5px);
  padding: 30px 40px 20px;
  @include afterboth;
  h5 {
    font-size: 20px;
    font-family: $raleway;
    font-weight: 700;
  }
  .side-th {
    color: #acacac;
  }
}

.thumb-other-news {
  margin: 0 -15px;
  @include afterboth;
  .side-th {
    float: left;
    width: 50%;
    @include boxSizing(border-box);
    padding: 0 15px;
    margin-bottom: 15px;
  }
}

.box-rounded {
  padding: 40px 50px;
  @include borderRadius(5px);
  background: #f8f8f8;
  border: 1px solid #eaeaea;
  @include boxSizing(border-box);
  margin-bottom: 40px;

  &.nomarg {
    margin-bottom: 0;
  }
}
.error-page {
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .in-error-page {
    width: 750px;
    max-width: 85%;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    @include transform(translateY(-50%));
    color: $white;
    text-align: center;
    h4 {
      font-size: 22px;
      line-height: 1.3;
      margin-bottom: 30px;
    }
    a {
      font-size: 19px;
      font-weight: 700;
      display: inline-block;
      border-bottom: 1px solid $blue;
      padding-bottom: 2px;
    }
  }
}
.banner-detail {
  position: relative;
  margin-bottom: 50px;
  figure {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("../images/material/fg-banner-landscape.png") no-repeat
        bottom center;
      background-size: 100%;
    }
    img {
      width: 100%;
    }
  }
  h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
  }
  .label {
    margin-bottom: 20px;
  }
  .text {
    position: absolute;
    bottom: 65px;
    left: 65px;
    right: 65px;
    color: $white;
  }
}
.thumb-img {
  @include afterboth;
  .ls-thumb-img {
    float: left;
    width: 49.4%;
    margin-bottom: 10px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      /*background: url('../images/material/fg-slider-lg.png') no-repeat center bottom;background-size: 100%;*/
      background: rgba(255, 255, 255, 0);
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(21, 21, 21, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(50%, rgba(255, 255, 255, 0)),
        color-stop(100%, rgba(21, 21, 21, 1))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(21, 21, 21, 1) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(21, 21, 21, 1) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(21, 21, 21, 1) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(21, 21, 21, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#292929', GradientType=0 );
    }
    img {
      width: 100%;
    }
    span {
      position: absolute;
      bottom: 25px;
      left: 0;
      right: 0;
      padding: 0 25px;
      font-size: 16px;
      line-height: 24px;
      color: $white;
      z-index: 2;
      max-height: 48px;
      overflow: hidden;
      word-wrap: break-word;
      @include boxSizing(border-box);
    }
    &:nth-child(odd) {
      clear: both;
    }
    &:nth-child(even) {
      float: right;
    }
  }
}
.error-page {
}

/* footer
----------------------------------------------------------------------------------------------*/
footer {
  background: $darkblue;
  color: #3c525f;
  font-family: $raleway;
  font-weight: 600;
  padding: 30px 0 36px;
  a {
    color: #3c525f;
  }
  .foot-left {
    float: left;
    line-height: 32px;
  }
  .foot-right {
    float: right;
  }

  &.footmin {
    margin-top: -101px;
    position: relative;
    z-index: 2;
  }
  &.foot-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
